import React from 'react';
import './ArchivageGed.scss';
import DoneAllIcon from '@material-ui/icons/DoneAll';

function ArchivageGed() {
    return (
        <div className="archivageGed container">
            <div className="row" data-aos="fade-up">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <img src="/assets/images/capturearchive.png" alt=""/>
                    <p>
                    CENTECH, qualifie les besoins, identifie les goulots d’étranglements 
                    et résout les problématiques documentaires des entreprises.
                    </p>
                </div>
                <div className="col-12 col-md-6 archivageGed__rightText" data-aos="fade-left">
                    <p className="">
                        <DoneAllIcon />
                        Sans cesse formées aux dernières technologies du secteur, nos équipes sont certifiées 
                        sur tous les produits distribués, à un haut niveau, et reconnues par les éditeurs leaders 
                        du marché.
                    </p>
                    <p className="">
                        <DoneAllIcon />
                        Nous organisons le transfert de compétences afin de répondre à leur besoin d’autonomie.
                    </p>
                    <p className="">
                        <DoneAllIcon />
                        Nous organisons le transfert de compétences afin de répondre à leur besoin d’autonomie.
                    </p>
                    <p className="">
                        Nous accompagnons nos partenaires distributeurs et intégrateurs selon leurs besoins. 
                        Nous organisons le transfert de compétences afin de répondre à leur besoin d’autonomie.
                    </p>
                </div>
            </div>

            <div className="row archivageGed__title">
                    <div className="col-12">
                        <h2>NOS COMPÉTENCES</h2>
                    </div>
            </div>
            
            <div className="arhivageGed__2 row">
                <div className="col-12 col-md-4" data-aos="zoom-in">
                    <img src="/assets/images/cp.png" alt=""/>
                    <p className="">
                        La capture permet de faire de tout document une valeur ajoutée pour 
                        l’entreprise. La numérisation simple d’un document crée une image 
                        au contenu difficilement exploitable Couplée avec une solution de
                        routage plus ou moins automatisée, les solutions de capture 
                        permettent d’alimenter la plateforme d’informations transversale 
                        qu’est la GED, les process workflow et toutes les applications 
                        tierces dans l’entreprise.
                    </p>
                </div>

                <div className="col-12 col-md-4" data-aos="zoom-in">
                    <img src="/assets/images/dem.png" alt=""/>
                    <p className="">
                        La dématérialisation n’est pas une finalité en soi, ni une étape 
                        unique, c’est la composante indispensable d’un projet de gestion 
                        électronique de documents et d’automatisation de process métier. 
                        La numérisation est la première pierre d’une chaîne de traitement 
                        automatisée et dépend à la fois du matériel et des logiciels qui 
                        vont traiter le fichier et ses données, pour en tirer parti.
                    </p>
                </div>

                <div className="col-12 col-md-4" data-aos="zoom-in">
                    <img src="/assets/images/arch.png" alt=""/>
                    <p className="">
                        Les informations contenues dans les documents d’une organisation, 
                        quelque que soient leur nature, constituent le noyau de son activité. 
                        Une solution de GED (gestion électronique de documents) ou ECM 
                        (entreprise content management – gestion de contenus) constitue un outil 
                        transversal essentiel à la performance de l’entreprise.
                    </p>
                </div>

                <div className="col-12 col-md-4" data-aos="zoom-in">
                    <img src="/assets/images/accompag.png" alt=""/>
                    <p className="">
                        Accompagnement et conduite de projet Maîtrise du processus par des 
                        ressources expertes en gestion de projet et services de conduite du 
                        changement.
                    </p>
                </div>

                <div className="col-12 col-md-4" data-aos="zoom-in">
                    <img src="/assets/images/formation.png" alt=""/>
                    <p className="">
                        Formations, certifications et transfert de compétences Formation 
                        utilisateurs et administrateurs, certification partenaires.
                    </p>
                </div>

                <div className="col-12 col-md-4" data-aos="zoom-in">
                    <img src="/assets/images/suivi.png" alt=""/>
                    <p className="">
                        Suivi & Maintenance Support des installations, mise en relation avec 
                        les éditeurs et assistance à la mise à jour des logiciels.
                    </p>
                </div>


            </div>
        </div>
    )
}

export default ArchivageGed
