import React from 'react';
import { Link } from 'react-router-dom';
import './ExpeClient.scss';

const ExpeClient = () =>{
    return(
        <div className="expeClient container">
            <div className="row expeClient__title">
                <div className="col-12">
                    <h2>DES SOLUTIONS D'EXPÉRIENCE CLIENT</h2>
                </div>
            </div>

            <Link to="/portfolio/transformation-digitale/gestion-de-file-dattente">                
                <div className="row" data-aos="fade-right">
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/attente1.jpg" alt=""/>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>                        
                            <p>
                                <h3>Systèmes de gestion de files d'attente</h3>
                            </p>
                            <p>
                                Depuis 27 ans, Wavetec a complètement changé la façon dont l'entreprise organise, mobilise et gère les clients dans l'espace d'attente.
                            </p>
                        </p>
                    </div>
                </div>
            </Link>

            <Link to="/portfolio/transformation-digitale/affichage-dynamique">
                <div className="row" data-aos="fade-left">
                    <div className="col-12 col-md-6">
                        <p>
                            <h3>Affichage dynamique</h3>
                        </p>
                        <p>
                            Les solutions d'affichage dynamique de Wavetec permettent aux entreprises de diffuser de façon maitriser leur contenu numérique.
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/attente2.jpg" alt=""/>
                    </div>
                </div>
            </Link>

            <Link to="/portfolio/transformation-digitale/satisfaction-client">
                <div className="row" data-aos="fade-right">
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/attente3.jpg" alt=""/>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>                        
                            <p>
                                <h3>Satisfaction client</h3>
                            </p>
                            <p>
                                Notre système de gestion de la satisfaction client configurable peut collecter et analyser les opinions des clients en temps réel pour améliorer continuellement les services
                            </p>
                        </p>
                    </div>
                </div>
            </Link>

            <Link>
                <div className="row" data-aos="fade-left">
                    <div className="col-12 col-md-6">
                        <p>
                            <h3>Conception d'espace clientèle</h3>
                        </p>
                        <p>
                            Notre système de gestion de la satisfaction client configurable peut collecter et analyser les opinions des clients en temps réel pour améliorer continuellement les services
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/attente4.jpg" alt=""/>
                    </div>
                </div>
            </Link>

        </div>
    )
}

export default ExpeClient