import React, { useEffect } from "react";
import Header from "./Header";
import { BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Home from "./Home";
import Services from "./Services";
import Portfolio from "./Portfolio"
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";
import ArchivageGed from "./ArchivageGed";
import Infogerance from "./Infogerance";
import MiMoney from "./MiMoney";
import Surete from "./Surete";
import Securite from "./Securite";
import Certifications from "./Certifications";
import GestionTemps from "./GestionTemps";
import Digitalisation from "./Digitalisation";
import Team from "./Team";
import Contact from "./Contact";
import Forums from "./Forums";
import ScrollToTop from "./ScrollToTop";

function App() {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Header/>

        <Switch>

          <Route path="/portfolio/forums-salons">
            <Forums />
          </Route>

          <Route path="/portfolio/transformation-digitale">
            <Digitalisation />
          </Route>

          <Route exact path="/portfolio/gestion-temps">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/gestion-temps", content: "Gestion du temps"} ]} />
            <GestionTemps />
          </Route>

          <Route exact path="/portfolio/certifications">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/certifications", content: "Certifications"} ]} />
            <Certifications />
          </Route>

          <Route exact path="/portfolio/securite-electronique">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/securite-electronique", content: "Sécurité electronique"} ]} />
            <Securite />
          </Route>

          <Route exact path="/portfolio/surete-electronique">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/surete-electronique", content: "Sûreté electronique"} ]} />
            <Surete />
          </Route>

          <Route exact path="/portfolio/mi-money">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/mi-money", content: "Mi Money"} ]} />
            <MiMoney />
          </Route>

          <Route exact path="/portfolio/infogerance-leasing">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/infogerance-leasing", content: "Infogérance et Leasing"} ]} />
            <Infogerance />
          </Route>
          
          <Route exact path="/portfolio/archivage-ged">
            <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, {link: "/portfolio/archivage-ged", content: "Archivage et Ged"} ]} />
            <ArchivageGed />
          </Route>

          <Route exact path="/portfolio">
                <Breadcrumb items={[{link: "/portfolio", content: "Portfolio"}, ]} />
                <Portfolio />            
          </Route>

          <Route exact path='/contact'>
            <Breadcrumb items={[{link: "/contact", content: "Contact"}, ]} />
            <Contact />
          </Route>

          <Route exact path='/team'>
            <Breadcrumb items={[{link: "/team", content: "Notre équipe"}, ]} />
            <Team />
          </Route>

          <Route exact path='/services'> 
            <Breadcrumb items={[{link: "/contact", content: "Services"}, ]} />    
            <Services />
          </Route>

          <Route exact path='/accueil'>
            <Home/>    
          </Route>

          <Redirect to="/accueil" />

        </Switch>

        <Footer/>
        
      </Router>

    </div>
  );
}

export default App;
