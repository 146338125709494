import React from 'react';
import "./Surete.scss"

function Surete() {
    return (
        <div className="surete container">
            <div className="row" data-aos="fade-down">
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/suretec2.png" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <h3>Maintenance préventive de parcs informatiques</h3>
                    <p>                        
                        Un réseau bien construit nécessite toujours un suivi régulier et efficace, 
                        tant par souci de règlage des cas d'urgence que par volonté de suivre l'état du matériel, 
                        ses évolutions futures... CENTECH GROUPE vous propose de vous appuyer sur son expérience 
                        pour aborder ce point en toute sérénité.
                    </p>
                </div>
            </div>

            <div className="row" data-aos="fade-up">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <h3>Surveillance du parc matériel</h3>
                    <p>
                        Beaucoup de problèmes sur un réseau peuvent être prévus, anticipés. Qu'il s'agisse de 
                        problèmes d'espace disque, d'un matériel en fin de vie, il est tout à fait possible d'éviter 
                        une partie des situations d'urgence en mettant en place un plan de surveillance efficace et 
                        régulier.
                    </p>
                    <p>
                        Nous sommes alors en mesure de vous indiquer quelle opération mener pour éviter un problème à 
                        venir, quel réajustement serait intéressant pour optimiser votre parc par rapport à une nouvelle 
                        situation de travail .
                    </p>

                    <h3>Surpervision des serveurs</h3>
                    <p>
                        Lors de maintenances trimestrielles à mensuelles, nos techniciens étudient l'état physique et 
                        logique de vos serveurs, et établissent un rapport préventif vous alertant en cas de problème logiciel
                        ou matériel comme un problème de mémoire, espace disque insuffisant.
                    </p>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/surete-removebg-preview.png" alt=""/>
                </div>
            </div>       

            <div className="row" data-aos="fade-down">
                <div className="col-12">
                    CENTECH ingénierie informatique vous propose de lui confier vos contrats de maintenance, 
                    afin de suivre au jour le jour l'évolution de votre parc informatique. Au delà de la 
                    résolution des problèmes, nous vous conseillerons sur la bonne marche de votre réseau 
                    informatique, de la connexion Internet aux machines client.
                </div>
            </div>     
        </div>
    )
}

export default Surete
