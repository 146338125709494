import React from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, CardText, CardTitle } from 'reactstrap';
import './Services.scss';

function Services() {
    const history = useHistory();

    return (
        <div className="services container">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="services__card">
                        <Card>
                            <CardImg
                                top
                                width="100%"
                                src="/assets/images/Capturewave1.png"
                                alt="Card image"
                            />
                            <CardBody>
                                <CardTitle tag="h4">
                                    Système de gestion des files d'attente
                                </CardTitle>
                                <CardText>
                                    La solution d'entreprise clé en main de Centech est conçue pour aider les 
                                    entreprises à gérer efficacement les zones de service, à réduire les temps 
                                    d'attente et à améliorer l'efficacité du service.
                                </CardText>
                                <Button onClick={ () => history.push("/portfolio/transformation-digitale/gestion-de-file-dattente")}>
                                    En savoir plus
                                </Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="services__card">
                        <Card>
                            <CardImg
                                top
                                width="100%"
                                src="/assets/images/wave2.png"
                                alt="Card image"
                            />
                            <CardBody>
                                <CardTitle tag="h4">
                                    Commentaires des clients
                                </CardTitle>
                                <CardText>
                                    Opinion Plus - Un système de rétroaction avancé qui permet aux entreprises de 
                                    collecter des informations en temps réel sur les opinions des clients et les 
                                    expériences de service.
                                </CardText>
                                <Button onClick={() => history.push("/portfolio/transformation-digitale/satisfaction-client")}>
                                    En savoir plus
                                </Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="services__card">
                        <Card>
                            <CardImg
                                top
                                width="100%"
                                src="/assets/images/wave3.png"
                                alt="Card image"
                            />
                            <CardBody>
                                <CardTitle tag="h4">
                                    Kiosques libre-service
                                </CardTitle>
                                <CardText>
                                    Notre gamme de bornes libre-service donne à vos clients le choix et la flexibilité 
                                    d'effectuer leurs transactions à n'importe quel endroit de manière plus pratique.
                                </CardText>
                                <Button>
                                    En savoir plus
                                </Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Services
