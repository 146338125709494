import React from 'react';
import "./Certifications.scss";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

function Certifications() {
    return (
        <div className="certifications container">
            <div className="row certifications__title">
                <div className="col-12">
                    <h2>NOS CERTIFICATIONS</h2>
                </div>
                <hr/>
            </div>

            <div className="row">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <img src="/assets/images/prime.jpg" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <p>                        
                        Professionnel ou entreprise à la recherche d’une homologation ou 
                        qualification en virtualisation, gestion de réseau et de système, 
                        marketing numérique, gouvernance SI, cloud, bureau, etc.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <img src="/assets/images/perfect.jpg" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <p> 
                        Professionnel particulier ou étudiant à la recherche d’une homologation, 
                        aptitude ou réorientation autour d'une carrière numérique et financez 
                        vous-même la formation.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <img src="/assets/images/level.jpg" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <p>                        
                        Souhaitez-vous améliorer vos connaissances numériques de façon simple 
                        et optimisé, vous pouvez jouir de nos très courts séminaires sur divers 
                        sujets captivants.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <img src="/assets/images/services.jpg" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <p>                        
                        Besoin d’une équipe professionnelle pour améliorer vos supports de 
                        communication, la maintenance de votre réseau et système ou 
                        l’organisation d’un séminaire.
                    </p>
                </div>
            </div>

            <div className="row certifications__title">
                <div className="col-12">
                    <h2>NOS FORMATIONS</h2>
                </div>
                <hr/>
            </div>

            <div className="row">
                <div className="col-12">
                    <p>
                        <RadioButtonUncheckedIcon/>
                        Administration Réseaux et systèmes (CCNP security/ sVPN 300-730, CCNA 
                        200-301, CCNP Entreprise | ENARSI, CCNP Entreprise | ENCOR, MCSA 70-412
                         : Configuration des services avancés Win Server 2012 R2, MCSA 70-411 : 
                         Administration de Win Server 2012 R2, MCSA 70-410 : Installation et 
                         configuration, CCNP security / scor 350-701 )
                    </p>

                    <p>
                        <RadioButtonUncheckedIcon/>
                        ICDL (Outils bureautiques Avancés, excel avance, excel base, icdl standard)
                    </p>

                    <p>
                        <RadioButtonUncheckedIcon/>
                        Métiers du web (Internationale en Marketing Digital DMI Pro)
                    </p>

                    <p>
                        <RadioButtonUncheckedIcon/>
                        Sessions programmées (CCNP Entreprise | ENCOR, MCSA 70-410 : 
                        Installation et configuration, CCNP security / scor 350-701, 
                        Internationale en Marketing Digital DMI Pro)
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Certifications
