import React from 'react'
import "./GestionTemps.scss";

function GestionTemps() {
    return (
        <div className="gestionTemps container">
            <div className="row">
                <div className="col-12">
                    <h2 className="gestionTemps__title">Avec nos solutions reprenez un semblant de contrôle sur votre temps.</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <p>Un savoir centenaire en horlogerie d’édifice, équipements et entretien.</p>
                </div>
                <div className="col-12 col-md-6">
                    <p className="gestionTemps__card">CAMPANAIRE</p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <p className="gestionTemps__card">SOFTWARE</p>
                </div>
                <div className="col-12 col-md-6">
                    <p>Une gamme complète de solution de gestion des temps, contrôle d’accès, SIRH et badgeuses.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <p>Solutions dédiées à l’affichage sportif : tableaux de scores, affichage vidéo LED, pupitre tactile.</p>
                </div>
                <div className="col-12 col-md-6">
                    <p className="gestionTemps__card">SPORT</p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <p className="gestionTemps__card">TIME</p>
                </div>
                <div className="col-12 col-md-6">
                    <p>Gammes d’horloges avec distribution horaire, système de sonneries et affichage dynamique.</p>
                </div>
            </div>
        </div>
    )
}

export default GestionTemps
