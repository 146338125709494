import React from "react";
import "./FileMobile.scss";
import DoneAllIcon from "@material-ui/icons/DoneAll";

function FileMobile() {
  return (
    <div className="fileMobile container">
      <div className="row">
        <div className="col-12 fileMobile__title">
          <h2>SYSTÈME DE GESTION MOBILE DE FILE D’ATTENTE</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-12 fileMobile__image">
          <img src="/assets/images/digitalisation/img32.png" alt="" />
        </div>
      </div>

      <div className="row fileMobile__content">

        <div className="col-12 col-md-6">
          <p>
            Un système de gestion des files d'attente est intégré à votre
            appareil mobile pour réduire le temps d'attente et simplifier les
            processus client, tout en offrant aux clients la possibilité de
            rejoindre la file d'attente avant de partir. « En fait, atteindre
            une succursale. Mobile-Q est l'avenir des files d'attente.
            L'application est conçue pour éliminer les tracas d'attente et
            permettre facilement à vos clients d'entrer dans la file d'attente
            virtuelle via l'application mobile. Avec l'aide de l'application de
            file d'attente de Centech, vous pouvez permettre aux clients de
            sélectionner la zone de service souhaitée, d'obtenir des
            instructions, de recevoir des notifications SMS / SMS et d'obtenir
            des billets avant l'arrivée.
          </p>
        </div>

        <div className="col-12 col-md-6">
          <p>
            Mobile-Q est le système de file d'attente de troisième génération, qui permet aux clients disposant de smartphones 
            d'obtenir des tickets de file d'attente à l'agence la plus proche et de faire la file à distance pour poursuivre leurs 
            activités. Les clients peuvent également afficher les informations sur la file d'attente en temps réel et suivre leurs 
            tours.
          </p>
        </div>
      </div>

      <div className="row">
          <div className="col-12 fileMobile__title">
            <h2>Vos Clients n’attendront plus jamais dans une file d’attente !</h2>
            <h4>Un grand pas dans le futur avec l'incroyable application de file d'attente</h4>
            <h4>       
              “Plus de 100 000 clients ont réduit leur temps d’attente en
              utilisant Mobile-Q“
            </h4>
          </div>
      </div>

      <div className="row fileMobile__flexRow fileMobile__flexRowFirst fileMobile__flexRowSecond">
        <div className="col-6 col-md-4 col-xl-2">
          <img src="/assets/images/digitalisation/img33.png" alt="" />
          Intégrée au système de gestion des files d’attente
        </div>

        <div className="col-6 col-md-4 col-xl-2">
          <img src="/assets/images/digitalisation/img34.png" alt="" />
          Permet aux clients d’entrer dans une file d’attente via un smartphone
        </div>

        <div className="col-6 col-md-4 col-xl-2">
          <img src="/assets/images/digitalisation/img35.png" alt="" />
          Dirige les clients vers la succursale la plus proche
        </div>

        <div className="col-6 col-md-4 col-xl-2">
          <img src="/assets/images/digitalisation/img36.png" alt="" />
          Mises à jour en temps réel sur l’état de la file d’attente
        </div>

        <div className="col-6 col-md-4 col-xl-2">
          <img src="/assets/images/digitalisation/img37.png" alt="" />
          Attribue des tickets, envoie des alertes et donne des rendez-vous
        </div>
      </div>

      <div className="row">
          <div className="col-12 fileMobile__title">
            <h2>Comment fonctionne Mobile-Q ?</h2>
          </div>
      </div>

      <div className="row fileMobile__content">
        <div className="col-12">
          <p>
            Mobile-Q est le système de file d'attente de troisième génération, qui permet aux clients disposant de smartphones d'obtenir 
            des tickets de file d'attente à l'agence la plus proche et de faire la file à distance pour poursuivre leurs activités. Les clients 
            peuvent également afficher les informations sur la file d'attente en temps réel et suivre leurs tours.
          </p>
        </div>
      </div>

      <div className="row fileMobile__flexRow fileMobile__flexRowFirst fileMobile__flexRowSecond">
        <div className="col-12 col-md-4">
          <img src="/assets/images/digitalisation/img38.png" alt="" />
          Sélectionnez la succursale la plus proche
        </div>

        <div className="col-12 col-md-4">
          <img src="/assets/images/digitalisation/img39.png" alt="" />
          Sélectionnez le service requis
        </div>

        <div className="col-12 col-md-4">
          <img src="/assets/images/digitalisation/img40.png" alt="" />
          Sélectionnez le service requis
        </div>

      </div>
      
      <div className="row">
          <div className="col-12 fileMobile__title">
            <h2>Pourquoi Mobile-Q ?</h2>
          </div>
      </div>

      <div className="row fileMobile__content">
        <div className="col-12">
          <p>
            Conçu pour éliminer les zones encombrées et les files d'attente physiques, Mobile-Q est un système de gestion de file 
            d'attente mobile qui peut répondre aux besoins et aux attentes des nouveaux clients numériques. Mobile-Q est simple, 
            pratique et permet aux clients de gagner un temps d'attente précieux.
          </p>
        </div>

        <div className="col-12 col-md-6">
          <p>
            <DoneAllIcon/>
            Donner des rendez-vous pour gérer le trafic client
          </p>
          <p>
            <DoneAllIcon/>
            Livrer des messages ciblés et des offres aux clients
          </p>
        </div>

        <div className="col-12 col-md-6">
          <p>
            <DoneAllIcon/>
            Recueillir le feedback des clients sur leurs expériences de service
          </p>
          <p>
            <DoneAllIcon/>
            Fournir aux clients des informations en file d’attente en temps réel
          </p>
        </div>

      </div>

      <div className="row">
          <div className="col-12 fileMobile__title">
            <h2>Avantages de Mobile-Q ?</h2>
          </div>
      </div>

      <div className="row fileMobile__flexRow fileMobile__flexRowFirst fileMobile__flexRowSecond">
        <div className="col-12 col-sm-6 col-lg-4">
          <img src="/assets/images/digitalisation/img33.png" alt="" />
          Mobile-Q peut être intégrée à l’application mobile existante d’une entreprise en intégrant l’API
        </div>

        <div className="col-12 col-sm-6 col-lg-4">
          <img src="/assets/images/digitalisation/img17.png" alt="" />
          Meilleure utilisation des ressources et de l’espace physique affectés aux zones d’attente
        </div>

        <div className="col-12 col-sm-6 col-lg-4">
          <img src="/assets/images/digitalisation/img22.png" alt="" />
          S’adapte facilement au trafic sur place
        </div>

        <div className="col-12 col-sm-6 col-lg-4">
          <img src="/assets/images/digitalisation/img19.png" alt="" />
          Personnalisation de l’image de marque et de l’interface utilisateur selon les spécifications souhaitées
        </div>

        <div className="col-12 col-sm-6 col-lg-4">
          <img src="/assets/images/digitalisation/img34.png" alt="" />
          Plus d’opportunités pour créer des expériences multicanales
        </div>

        <div className="col-12 col-sm-6 col-lg-4">
          <img src="/assets/images/digitalisation/img20.png" alt="" />
          Crée une expérience client efficace et sans faille
        </div>

      </div>


    </div>
  );
}

export default FileMobile;
