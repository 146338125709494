import React from 'react';
import './Portfolio.scss';
import PortfolioItem from './PortfolioItem';

function Portfolio() {
    return (
        <>
        <div className="portfolio container">
            <div className="row">
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/transformation-digitale"
                        imageSrc="/assets/images/TRANSFORMATION_DIGITALE.jpg"
                        title="Transformation Digitale"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/archivage-ged"
                        imageSrc="/assets/images/Capturearchive2.png"
                        title="Archivage et GED"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/infogerance-leasing"
                        imageSrc="/assets/images/INFOGERANCE_LEASING.jpg"
                        title="Infogérance et Leasing"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/accueil"
                        imageSrc="/assets/images/miagbo.png"
                        title="Shop"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/mi-money"
                        imageSrc="/assets/images/moneycapture.png"
                        title="Mi Money"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/securite-electronique"
                        imageSrc="/assets/images/SECURITE_INFORMATIQUE.jpg"
                        title="Sécurité Informatique"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/surete-electronique"
                        imageSrc="/assets/images/su.jpg"
                        title="Sûreté Electronique"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/certifications"
                        imageSrc="/assets/images/FORMATION_ET_CERTIFICATION.jpg"
                        title="Certifications"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/services"
                        imageSrc="/assets/images/INCUBATION_DE_STARTUP.jpg"
                        title="Incubation de Startups"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/gestion-temps"
                        imageSrc="/assets/images/emotion-horloge.jpg"
                        title="Gestion du Temps"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/services"
                        imageSrc="/assets/images/IMO.jpg"
                        title="Immobilier"
                    />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <PortfolioItem
                        link="/portfolio/forums-salons"
                        imageSrc="/assets/images/img17.jpg"
                        title="Forums et Salons"
                    />
                </div>
                
            </div>
        </div>
        </>
    )
}

export default Portfolio
