import React from "react";
import "./Video.scss";

function Video({ config }) {
  return (
    <div className="video container">
      <div className="video__title row">
        <div className="col-12">
          <h2>{config.title}</h2>
        </div>
      </div>
      {config.list.map(({ linkEnd, title, description }) => (
        <>
          <div className="row">
            <div className="col-12">
              <YoutubeVideo
                linkEnd={linkEnd}
                title={title}
                description={description}
              />
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default Video;

function YoutubeVideo({ linkEnd, title, description }) {
  const titleStyle = {
    fontSize: "1.5em",
    fontWeight: 500,
  };
  const descriptionStyle = {};

  return (
    <div
      className="container"
      style={{
        margin: "30px 0",
      }}
    >
      <div className="row">
        <div className="col-12 col-md-6">
          <div style={titleStyle}>{title}</div>
          <div style={descriptionStyle}>{description}</div>
        </div>
        <div className="col-6 col-md-6">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${linkEnd}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
