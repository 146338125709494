import React from 'react';
import './FileVirtuelle.scss';

function FileVirtuelle() {
    return (
        <div className="fileVirtuelle container">
            <div className="row">
                <div className="col-12 fileVirtuelle__title">
                    <h2>SYSTÈME DE GESTION VIRTUELLE DE FILE D’ATTENTE</h2>
                    <h3>Changez la façon dont les clients s'attendent à fournir à nouveau des services</h3>
                </div>
            </div>

            <div className="row fileVirtuelle__content">
                <div className="col-12 col-md-6">
                    <p>
                        Utilisez notre système de gestion de file d'attente virtuelle pour contrôler l'expérience 
                        de mise en file d'attente du client. Le système de gestion de file d'attente d'entreprise 
                        virtuelle de Centech vous permet de définir comment gérer les clients dans la zone de service. 
                        Notre système de mise en file d'attente est un écosystème de modules interconnectés qui 
                        contribue à offrir une expérience personnalisée aux clients en attente.
                    </p>
                </div>

                <div className="col-12 col-md-6">
                    <p>
                        Du moment où le client arrive dans la zone de service au moment où le client quitte, 
                        nous planifierons tout pour vous grâce à une solution de rotation complète. Depuis 27 ans, 
                        Centech change la façon dont les entreprises créent et fournissent des services. Centech 
                        fournit un système de gestion des files d'attente virtuelles (SMQ) à l'échelle de 
                        l'entreprise avec la dernière conception et un système d'information standard personnalisable 
                        à l'échelle de l'entreprise pour cartographier les processus suivants: clients et améliorer 
                        l'expérience client.
                    </p>
                </div>

            </div>

            <div className="row">
                <div className="col-12 fileVirtuelle__title">
                    <h2>Composantes Fondamentales & Optionnelles</h2>
                    <h3>Comment ça marche ?</h3>
                </div>
            </div>

            <div className="row fileVirtuelle__flexRow fileVirtuelle__flexRowFirst">
                <div className="col-12">
                    <h5>1. Les clients prennent un ticket.</h5>
                    <p>Les clients entrent dans une file virtuelle en prenant un ticket par l’un des moyens suivants:</p>
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img1.png" alt=""/>
                    Application Mobile de File d’Attente
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img2.png" alt=""/>
                    Kiosque Automatique (Service libre)
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img3.png" alt=""/>
                    Rendez-vous en ligne
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img4.png" alt=""/>
                    Prise de Ticket par Internet
                </div>
            </div>

            <div className="row fileVirtuelle__flexRow">
                <div className="col-12">
                    <h5>2. Les clients vérifient leur statut d’attente par la signalisation numérique.</h5>
                    <p>En attendant le service, les clients utilisent des solutions d'affichage dynamique et des 
                        affichages de file d'attente informatifs, ainsi que des messages promotionnels et des 
                        informations sur la file d'attente pour rester en contact.
                    </p>
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img5.png" alt=""/>
                    Écran LCD de File d’Attente Donatello LCD
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img6.png" alt=""/>
                    Unité d’Affichage de Compteur avec/sans Fil
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img7.png" alt=""/>
                    Affichage de Compteur POE LCD
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img8.png" alt=""/>
                    État de l’Affichage de la Matrice de LED de File d’Attente
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img9.png" alt=""/>
                    Affichage LCD Informationnel sur la File d’Attente
                </div>
            </div>

            <div className="row fileVirtuelle__content">
                <div className="col-12">
                    <p>
                        Nous proposons une variété d'affichages de files d'attente différents pour répondre aux 
                        besoins des clients. Que vous souhaitiez afficher la file d'attente dans la salle d'attente 
                        pour informer les clients quand c'est leur tour, ou que vous souhaitiez afficher la marchandise 
                        sur le comptoir, nous pouvons le faire pour vous.
                    </p>
                </div>
            </div>

            <div className="row fileVirtuelle__flexRow">
                <div className="col-12">
                    <h5>3. Les clients sont avertis de leur tour.</h5>
                    <p>
                        Le personnel du service client utilise le logiciel et le matériel du terminal de poste de 
                        travail pour appeler, transférer et servir les clients.
                    </p>
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img10.png" alt=""/>
                    Tablette pour Appel de Client à la Caisse
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img11.png" alt=""/>
                    Télésurveillance
                </div>
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img12.png" alt=""/>
                    Logiciel d’Unité de la Caisse
                </div>
            </div>

            <div className="row fileVirtuelle__content">
                <div className="col-12">
                    <p>
                        Transformez votre caissier en vendeur. Notre terminal d'appel de poste de travail de comptoir 
                        permet aux employés d'appeler les clients au comptoir de service selon une priorité prédéfinie 
                        ou une méthode de segmentation. Notre solution offre également aux employés une vue en temps réel 
                        des temps d'attente des clients et des types de services requis.
                    </p>
                </div>
            </div>

            <div className="row fileVirtuelle__flexRow">
                <div className="col-12">
                    <h5>4. Le Feedback des Clients est reçu.</h5>
                    <p>
                        Une fois le service fourni, le client décrira son expérience via l'unité de rétroaction. 
                        Opinion Plus est notre solution de rétroaction propriétaire qui peut extraire les 
                        commentaires des clients sur leur expérience et savoir ce qu'ils attendent d'eux.
                    </p>
                </div>
                <div className="col-12">
                    <img className="fileVirtuelle__image" src="/assets/images/digitalisation/img13.png" alt=""/>
                    Les produits de la série Opinion Plus de Centech comprennent différents écrans tactiles que les 
                    clients peuvent utiliser pour fournir des commentaires sur leur expérience de service. Obtenir 
                    rapidement et facilement les commentaires des clients garantit un retour d'information quotidien 
                    inégalé. Planifiez des questions uniques, multiples ou récurrentes et obtenez des commentaires 
                    via différents canaux. Vous pouvez utiliser un terminal ou une tablette en libre-service, ou même 
                    une application mobile, pour offrir aux clients la possibilité d'enregistrer leurs commentaires.
                </div>
            </div>

            <div className="row fileVirtuelle__flexRow">
                <div className="col-12">
                    <h5>5.  Rapports, Tableaux de bord et Analyse prédictive.</h5>
                </div>
                <div className="col-12 col-md-6 fileVirtuelle__flexRowContent">
                    <p>
                        Les responsables peuvent utiliser un système de reporting centralisé pour accéder aux 
                        rapports et tableaux de bord en temps réel.
                    </p>
                    Le système de reporting en temps réel et le tableau de bord du gestionnaire de Centech ont été 
                    intégrés dans différents aspects du système de gestion des files d'attente pour former un module 
                    de business intelligence complet. Notre système de reporting est une solution d'entreprise complète 
                    qui peut extraire des informations complètes sur le flux des clients, les performances des employés 
                    et l'efficacité de la zone de service. Les responsables peuvent consulter les rapports régionaux et 
                    des succursales à partir d'un emplacement centralisé. Le tableau de bord en temps réel de Centech 
                    peut être utilisé pour générer des données de Business Intelligence, surveiller de manière centralisée 
                    la qualité du service et éliminer les écarts.
                </div>
                <div className="col-12 col-md-6">
                    <img className="fileVirtuelle__image" src="/assets/images/digitalisation/img14.png" alt=""/>
                </div>
            </div>

            <div className="row">
                <div className="col-12 fileVirtuelle__title">
                    <h2>Avantages & Caractéristiques</h2>
                </div>
            </div>

            <div className="row fileVirtuelle__flexRow fileVirtuelle__flexRowFirst fileVirtuelle__flexRowSecond">
                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img15.png" alt=""/>
                    Fusionne les différentes composantes du SMQ de façon transparente    
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img16.png" alt=""/>    
                    Contrôlable de manière centrale et facile à utiliser
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img17.png" alt=""/>  
                    Optimise la performance du personnel  
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img18.png" alt=""/>    
                    Permet de définir et de surveiller les KPI de service
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img19.png" alt=""/>    
                    Organise la Zone de Service et réduit les temps d’attente des clients
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img20.png" alt=""/>    
                    Permet une Gestion Efficace des clients
                </div>

            </div>
        </div>
    )
}

export default FileVirtuelle
