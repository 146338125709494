export const digitalisationVideos = {
  title: "VIDÉOS",
  list: [
    {
      linkEnd: "jaZqaAiANcc",
      title: "Dépôt de chèques",
      description: "",
    },
    {
      linkEnd: "0y21469rTTw",
      title: "Dépôt d'argent et paiement de factures",
      description: "",
    },
    {
      linkEnd: "jckaB9MN5Oc",
      title: "Ouverture de compte",
      description: "",
    },
    {
      linkEnd: "5NKSbaDvgPg",
      title: "Transformation digitale WAVETEC : Un tour d'horizon BHCI",
      description: "",
    },
    {
      linkEnd: "Llofhj3VGFE",
      title: "Présentation de Kiosque de dépôt de chèque",
      description: "",
    },
  ],
};

export const forumVideos = {
  title: "Forums et Salons en vidéos",
  list: [
    {
      linkEnd: "OPh5o05WlRE",
      title: "Résume Salon des Technologies et de l'innovation de Lomé 2019",
      description: "",
    },
    {
      linkEnd: "46MqB-bnaQ8",
      title: "LOME TECH EXPO Édition 2019 Le journal BUSINESS 24",
      description: "",
    },
    {
      linkEnd: "zmL-w0Lkd2Q",
      title: "Mini Résumé LTE 2019",
      description: "",
    },
    {
      linkEnd: "BWXjspCE5pA",
      title: "Mini Résumé des Pannels et JINNOV",
      description: "",
    },
    {
      linkEnd: "O7yJfzxP6Nw",
      title: "Résumé Forum Telecom Edition 2019",
      description: "",
    },
    {
      linkEnd: "V3Y8CnYIhLk",
      title: "CENTECH & Jack Ma à Lomé 2019",
      description: "",
    },
  ],
};
