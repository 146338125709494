import React, { useState } from 'react';
import './Footer.scss';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

function Footer() {
    const [newsletterEmail, setNewsletterEmail] = useState("");

    const handleSubmit = (e) =>{
        e.preventDefault();
    }


    return (
        <>
        <div className="footer">
            <div className="container">
                <div className="row footer__essentials">
                    <div className="col-12 col-md-3">
                        <p className="footer__title">
                            CENTECH
                        </p>
                        <p className="footer__text">
                            138, Boulevard du 13 Janvier <br/>
                            Hanoukopé, Lomé <br/>
                            TOGO <br/>
                        </p>
                        <p>
                        <PhoneIcon/> <a className="footer__text" href="tel:0022896222314">+228 96 22 23 14</a> / <a className="footer__text" href="tel:0022890380004">90 38 00 04</a> <br/>
                        <EmailIcon/> <a className="footer__text" href="mailto:info@centech-togo.com">info@centech-togo.com</a>
                        </p>
                    </div>

                    <div className="col-12 col-md-3">
                        <p className="footer__title">
                            Liens Utiles
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/> 
                            <Link to="/accueil">
                                Accueil 
                            </Link>
                            
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/services">
                                Services
                            </Link>
                            
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/portfolio">
                                Portfolio
                            </Link> 
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/team">
                                Qui Sommes Nous ?
                            </Link> 
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/contact">
                                Contact
                            </Link> 
                        </p>

                    </div>

                    <div className="col-12 col-md-3">
                        <p className="footer__title">
                            Certains de nos services
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/> 
                            <Link to="/portfolio/archivage-ged">
                                ARCHIVAGE NUMERIQUE ET GED
                            </Link>
                            
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/portfolio/infogerance-leasing">
                                INFOGERANCE & LEASING
                            </Link> 
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/portfolio/securite-electronique">
                                SECURITE INFORMATIQUE
                            </Link> 
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/portfolio/certifications">
                                FORMATION ET CERTIFICATION
                            </Link> 
                        </p>
                        <p className="footer__text">
                            <ChevronRightIcon/>
                            <Link to="/portfolio/forums-salons">
                                ORGANISATION DE FORUMS ET SALONS TECHNOLOGIQUES
                            </Link> 
                        </p>

                    </div>

                    <div className="col-12 col-md-3">
                        <p className="footer__title">
                            Newsletter
                        </p>
                        <form action="" className="footer__form">
                            <input 
                                type="text"
                                value={newsletterEmail}
                                onChange={ e => setNewsletterEmail(e.target.value)}
                            />
                            <button onClick={handleSubmit}>Envoyer</button>
                        </form>

                    </div>

                </div>
            </div>
        </div>
        <div className="copyright">
            <div class="container d-md-flex py-4">
                <div class="mr-md-auto text-center text-md-left">
                <div class="copyright__text">
                    © Copyright <strong><span class="">Centech</span></strong>. All Rights Reserved
                </div>
                </div>
                <div class="copyright__social text-center text-md-right pt-3 pt-md-0">
                <a href="https://www.twitter.com">
                        <TwitterIcon className="header__socialIcon" />
                    </a>
                    <a href="https://www.facebook.com/CentechWavetecAfriqueFrancophone/">
                        <FacebookIcon className="header__socialIcon" />
                    </a>
                    <a href="https://instagram.com/centech.tg?igshid=jukhhmn84e96">
                        <InstagramIcon className="header__socialIcon" />
                    </a>
                    <a href="https://www.linkedin.com/company/centech-togo">
                        <LinkedInIcon className="header__socialIcon" />
                </a>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer
