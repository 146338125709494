import React from 'react';
import './FileLineaire.scss';

function FileLineaire() {
    return (
        <div className="fileLineaire container">
            <div className="row">
                <div className="col-12 fileLineaire__title">
                    <h2>SYSTÈME DE GESTION LINEAIRE DE FILE D’ATTENTE</h2>
                </div>
            </div>

            <div className="row fileLineaire__content">
                <div className="col-12 col-md-6">
                    <p>
                        Le transfert d'appels électronique plug and play UNO-Q est une solution de file d'attente 
                        rapide et facile à installer sans ticket. Les clients font la queue en personne et sont 
                        servis quand c'est leur tour et quand le comptoir s'ouvre. UNO-Q a considérablement amélioré 
                        l'expérience de mise en file d'attente linéaire, réduit le temps d'attente et amélioré 
                        l'efficacité opérationnelle.
                    </p>
                </div>

                <div className="col-12 col-md-6">
                    <p>
                        UNO-Q est une étape pour maximiser l'efficacité du service tout en simplifiant l'expérience 
                        de la file d'attente. En permettant aux clients de servir sur la base du premier arrivé, 
                        premier servi, UNO-Q est idéal pour simplifier les opérations de la zone de service. Il 
                        s'agit d'un système de base facile à installer et idéal pour les transactions courtes et 
                        simples. Les pharmacies, les cliniques, les petits centres de service, les laboratoires, 
                        les comptoirs de plats cuisinés, les centres de garantie et les centres de télécommunications 
                        évoluent tous vers un système de file d'attente linéaire pour optimiser les centres de service
                        de leur clientèle.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 fileLineaire__title">
                    <h2>Avantages & Caractéristiques</h2>
                </div>
            </div>

            <div className="row fileLineaire__flexRow fileLineaire__flexRowFirst fileLineaire__flexRowSecond">
                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img19.png" alt=""/>
                    Organise le Flux de Clients    
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img21.png" alt=""/>    
                    Améliore le flux de files d’attente de 30%
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img22.png" alt=""/>  
                    Réduit la fuite de clients de 80%  
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img23.png" alt=""/>    
                    Diminue le temps d’arrêt des compteurs
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                    <img src="/assets/images/digitalisation/img24.png" alt=""/>    
                    Réduit les temps d’attente de 40%
                </div>
            </div>

            <div className="row">
                <div className="col-12 fileLineaire__title">
                    <h2>Renvoi d’Appel Électronique</h2>
                </div>
            </div>

            <div className="row fileLineaire__content">
                <div className="col-12 col-md-6">
                    <p>
                        La fonction de transfert d'appel UNO-Q simple et complète de Centech simplifie les processus 
                        clients jusqu'à 32 mètres. Avec cette solution plug-and-play, les petits détaillants, 
                        pharmacies, restaurants, bureaux de poste ou chaînes de restauration rapide ont besoin d'un 
                        système simple et complet pour gérer les clients en attente. Centech fournit des solutions 
                        de mise en file d'attente personnalisables pour résoudre divers problèmes de mise en file 
                        d'attente auxquels les organisations sont confrontées lors de la gestion des foules et des 
                        files d'attente. 
                    </p>
                </div>

                <div className="col-12 col-md-6">
                    <p>
                        En utilisant un système de routage d'appels linéaire, vous pouvez suivre la durée moyenne 
                        du service client, l'efficacité du compteur et comprendre les heures de pointe. Notre 
                        solution de mise en file d'attente peut être intégrée à des solutions d'affichage dynamique 
                        et à des solutions de rétroaction client pour offrir une expérience client globale.
                    </p>
                </div>
            </div>
            

            <div className="row">
                <div className="col-12 fileLineaire__title">
                    <h2>Comment Fonctionne UNO-Q?</h2>
                </div>
            </div>

            <div className="row fileLineaire__content">
                <div className="col-12 col-md-6">
                    <p>
                        Les clients attendent dans une file d'attente; lorsque le comptoir s'ouvre, le caissier 
                        appuie sur le bouton sans fil et l'écran LED guide le client vers le comptoir ouvert, puis 
                        le client se déplace vers le comptoir pour le service.
                    </p>
                </div>

                <div className="col-12 col-md-6">
                    <p>
                        Notre système de file d'attente linéaire basé sur Donatello peut gérer rapidement les files 
                        d'attente sans ticket dans la zone de service. En raison de sa compatibilité avec l'affichage 
                        numérique pour une collaboration transparente, cette solution a l'avantage de contrôler les files 
                        d'attente tout en permettant aux clients d'obtenir plus d'informations et de publicités. Le tableau 
                        de bord de reporting est une partie importante de la solution et fournit des informations en temps 
                        réel sur la file d'attente.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 fileLineaire__title">
                    <h2>Composantes d’UNO-Q</h2>
                </div>
            </div>

            <div className="row fileLineaire__flexRow">
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img25.png" alt=""/>
                    Bouton sans fil    
                </div>

                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img26.png" alt=""/>    
                    Pivot Actif
                </div>

                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img27.png" alt=""/>  
                    Receveur sans fil  
                </div>

                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img28.png" alt=""/>    
                    LED PDU
                </div>

                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img29.png" alt=""/>    
                    Receveur sans fil
                </div>
                
                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img30.png" alt=""/>    
                    Écran LCD
                </div>

                <div className="col-12 col-md-6">
                    <img src="/assets/images/digitalisation/img31.png" alt=""/>    
                    Rapports
                </div>
            </div>


        </div>
    )
}

export default FileLineaire
