import React from 'react';
import "./Securite.scss"

function Securite() {
    return (
        <div className="securite container">
            <div className="row" data-aos="fade-up">
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/secureimg.jpg" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <h3>Pourquoi sécuriser l'informatique de votre entreprise ?</h3>
                    <p>                        
                        La nécessité de protéger les données sensibles ou confidentielles de l'entreprise implique 
                        la mise en place d'une politique de sécurité informatique. Certaines données ne doivent pas 
                        être accessibles de l'extérieur ou visibles par des groupes d'utilisateurs internes non 
                        autorisés. La vulnérabilité de ces données n'est pas envisageable, elles doivent être 
                        protégées contre tout accès non autorisé. Les risques sont importants, les données RH ne 
                        doivent absolument pas être diffusées à l'ensemble du personnel. Les données concernant les 
                        clients de l'entreprise ne doivent pas non plus être visibles de l'extérieur. De manière 
                        générale toutes les données sensibles ou confidentielles doivent faire l'objet d'une 
                        politique de sécurité informatiquevisant à les protéger contre tout accès non autorisé.
                    </p>
                </div>
            </div>

            <div className="row" data-aos="fade-up">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <h3>Comment protéger les données de l'entreprise ?</h3>
                    <p>
                        Dans le cadre de nos prestations de sécurité informatique, nous proposons un audit informatique 
                        pour déterminer les points de vulnérabilité de votre système d'information. Lors de cet audit nous étudions 
                        les possibilités d'accès et la pertinence des éléments de sécurité en place. Dans une seconde phase nous 
                        prescrirons l'installation d'éléments de sécurité tel qu'un Firewall, la mise en oeuvre d'une politique de 
                        gestion de compte utilisateur, des liaisons VPN, des solutions de cryptage des données.
                    </p>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/10.jpg" alt=""/>
                </div>
            </div>       

            <div className="row" data-aos="fade-up">
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/securiteimg-removebg-preview.png" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <h3>Qui est succeptible de pirater vos données ?</h3>
                    <p>
                        Il faut distinguer les risques internes et externes. En général les risques internes sont 
                        sous-estimés, ce qui créé des points sensibles et des risques réels de diffusion d'informations 
                        confidentielles. En ce qui concerne les risques internes les risques sont multiples ; personnels de passage, 
                        salariés indélicats, erreurs de manipulations. Les attaques de l'extérieur sont plus nombreuses ; pour les contrer 
                        il est nécessaire de mettre en place les éléments de sécurité efficaces. Généralement les sources d'attaques 
                        possibles sont les pirates ou les concurrents.
                    </p>
                </div>
            </div>     
        </div>
    )
}

export default Securite
