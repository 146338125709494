import React, { useState } from 'react';
import './Contact.scss';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = e => {
        e.preventDefault();



        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    return (
        <div className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <iframe 
                        className="contact__map" 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15867.876232441444!2d1.2168964!3d6.1348598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xffed327808a262c1!2sCentech%20-%20Wavetec%20Afrique%20Francophone!5e0!3m2!1sfr!2stg!4v1604585442700!5m2!1sfr!2stg" 
                        frameborder="0" 
                        allowfullscreen="" 
                        aria-hidden="false" 
                        tabindex="0" 
                        title="map"
                    >
                    </iframe>    

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row contact__firstRow">
                    <div className="col-12 col-lg-4">
                        <div className="contact__details">
                            <ContactDetail
                                Icon={LocationOnIcon}
                                title="Emplacement"
                                info1="138 Boulevard du 13 janvier"
                                info2="Hanoukopé, Lomé-TOGO"
                            />
                        </div>
                    </div>

                    <div className="col-12 col-lg-4">
                        <div className="contact__details">
                            <ContactDetail
                                Icon={EmailIcon}
                                title="Email"
                                info1='info@centech-togo.com'
                            />
                        </div>
                    </div>

                    <div className="col-12 col-lg-4">
                        <div className="contact__details">
                            <ContactDetail
                                Icon={PhoneIcon}
                                title="Appel"
                                info1='+228 96 22 23 14'
                            />
                        </div>
                    </div>
                </div>

                <div className="row contact__secondRow">
                    <div className="col-12">
                        <form action="">
                            <input
                                className="" 
                                type="text"
                                value={name}
                                placeholder="Nom"
                                onChange={e => setName(e.target.value) }
                            />

                            <input
                                className="" 
                                type="email"
                                value={email}
                                placeholder="Email"
                                onChange={e => setEmail(e.target.value)}
                            />

                            <input
                                className="" 
                                type="text"
                                value={subject}
                                placeholder="Sujet"
                                onChange={e => setSubject(e.target.value) }
                            />

                            <textarea
                                className="" 
                                value={message}
                                placeholder="Message"
                                onChange={e => setMessage(e.target.value) }
                                rows="5"
                            >
                            </textarea>

                            <button
                                onClick={handleSubmit}
                            >Envoyer le message</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact;

const ContactDetail = ({Icon, title, info1, info2}) =>{
    return(
        <div className="contactDetail">
            <Icon />
            <p>
                <h4>{title}</h4>
                <p>{info1}</p>
                <p>{info2}</p>
            </p>
        </div>
    )
}
