import React from 'react';
import { Link } from 'react-router-dom';
import './PortfolioItem.scss'

function PortfolioItem({link, imageSrc, title, forum}) {
    return (
        <div data-aos="flip-left">
            <div className="portfolioItem">
                <Link to={link}>
                    <img className={forum && "portfolioItem__forum"} src={imageSrc} alt=""/>
                    <div data-aos="fade-up">
                        <p className="portfolioItem__title">
                            {title}
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default PortfolioItem
