import React from 'react';
import "./Infogerance.scss";
import DoneAllIcon from '@material-ui/icons/DoneAll';


function Infogerance() {
    return (
        <div className="infogerance">
            <div className="infogeranceRow1">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6" data-aos="fade-right">
                            <p className="">
                                Pour les clients qui désirent externaliser leur système d’information, 
                                CENTECH propose ses services d’infogérance sur site.
                            </p> 
                            <img src="/assets/images/info3.jpg" alt=""/>

                        </div>
                        <div className="col-12 col-md-6" data-aos="fade-left">
                            <p className="">
                                En déléguant la gestion et la maintenance de votre parc informatique à un 
                                professionnel vous pouvez garder vos ressources sur le cœur de votre métier. 
                                Nos équipes prennent le relais du collaborateur en charge du service informatique, 
                                afin de vous proposer, selon votre besoin, des prestations d’accompagnement diverses.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="infogeranceRow2">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6" data-aos="fade-right">
                            <p className="">
                                <DoneAllIcon />
                                Audit et conseil
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Installations et paramétrages
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Gestion et exploitation quotidienne du parc informatique
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Définition et application des politiques de sécurité
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Maintenance et support utilisateur
                            </p>
                            <p className="">
                                Ces offres d’infogérance s’adaptent à la taille de votre parc informatique, 
                                à vos logiciels métier et à vos usages.
                            </p>
                        </div>
                        <div className="col-12 col-md-6" data-aos="fade-left">
                            <img src="/assets/images/infog4.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="infogeranceRow3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6" data-aos="fade-right">
                            <img src="/assets/images/infog2.jpg" alt=""/>
                        </div>
                        <div className="col-12 col-md-6" data-aos="fade-left">
                            <p className="">Les avantages de l’infogérance sur site:</p>
                            <p className="">
                                <DoneAllIcon />
                                Conservation de votre infrastructure en interne, seuls la gestion et l’exploitation 
                                sont déléguées
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Economie d’une ou plusieurs ressources pour la gestion de votre parc
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Souplesse et évolutivité des solutions offertes en fonction de vos besoins
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Infrastructure informatique performante et sécurisée
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Accompagnement par un expert pour vous conseiller dans vos choix
                            </p>
                            <p className="">
                                <DoneAllIcon />
                                Cependant, à tout moment, il vous est possible d’évoluer et de choisir entre 
                                l’infogérance sur site ou l’externalisation partielle ou totale de votre système 
                                d’information.
                            </p>
                            <p className="">
                                Pour tous les clients sous contrat de maintenance CENTECH dispose d’une interface 
                                en ligne accessible 24/ 24h, 7/7j pour la gestion de vos demandes d’interventions, 
                                le suivi des incidents et de leur résolution et pour toutes demandes spécifiques.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="infogeranceRow4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6" data-aos="fade-right">
                            <h3>Nous intervenons sur différents aspects :</h3>
                            <p>Diagnostic et identification de la panne</p>
                            <p>Établissement de devis pour remplacer le matériel en cause</p>
                            <p>Remplacement des pièces</p>
                            <p>Configuration de poste utilisateurs ou de serveurs</p>
                        </div>
                        <div className="col-12 col-md-6" data-aos="fade-left">
                            <img src="/assets/images/index.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Infogerance
