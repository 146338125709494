import React from "react";
import "./AffichageDynamique.scss";

function AffichageDynamique() {
  return (
    <div className="affichageDynamique container">
      <div className="row affichageDynamique__title">
        <div className="col-12">
          <h2>MOBILISER VOS CLIENTS</h2>
        </div>
      </div>

      <div className="row affichageDynamique__content">
        <div className="col-12">
          <p>
            Depuis plus de 27 ans, Centech installe des systèmes d'affichage
            numérique dans des entreprises du monde entier. En tant que solution
            d'affichage dynamique de Centech, Donatello est une fusion de pointe
            entre matériel et logiciel, qui permet à l'entreprise de communiquer
            avec les clients et de les mobiliser via un excellent réseau
            d'écrans numériques.
          </p>
        </div>
      </div>

      <div className="row affichageDynamique__title">
        <div className="col-12">
          <h2>Principaux avantages</h2>
        </div>
      </div>

      <div className="row affichageDynamique__flexRow affichageDynamique__flexRowFirst affichageDynamique__flexRowSecond">
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img37.png" alt="" />
          Planification et conception de modèles flexibles
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img34.png" alt="" />
          Télévision en direct en streaming
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img33.png" alt="" />
          Périphériques Plug & Play
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img34.png" alt="" />
          Images et vidéos haute définition
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img34.png" alt="" />
          Notifications d'alertes
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img36.png" alt="" />
          Mises à jour centralisées en temps réel
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img17.png" alt="" />
          Taux de change Forex, flux RSS, bandeau d'informations financières,
          météo, heure
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img19.png" alt="" />
          Outils d'intégration
        </div>
      </div>

      <div className="row affichageDynamique__title">
        <div className="col-12">
          <h2>Caractéristiques du produit</h2>
        </div>
      </div>

      <div className="row affichageDynamique__flexRow affichageDynamique__flexRowFirst">
        <div className="col-12 col-md-6">
          <h2>INTERACTIVITÉ</h2>
          <h6>
            L'affichage numérique interactif capte l'attention des clients et
            garantit qu'ils ont un plus grand degré d'interaction avec votre
            marque.
          </h6>
        </div>
        <div className="col-12 col-md-6">
          <h2>CENTRALISÉ</h2>
          <h6>
            L'affichage numérique interactif capte l'attention des clients et
            garantit qu'ils ont un plus grand degré d'interaction avec votre
            marque.
          </h6>
        </div>
        <div className="col-12 col-md-6">
          <h2>DYNAMIQUE</h2>
          <h6>
            Avec Donatello, le contenu dynamique peut être facilement modifié et
            géré pour fournir aux clients un contenu à jour et engageant à
            toutes les étapes.
          </h6>
        </div>
        <div className="col-12 col-md-6">
          <h2>INTEGRATION</h2>
          <h6>
            Notre application d'affichage numérique Donatello s'intègre
            parfaitement aux solutions tierces pour faciliter l'utilisation de
            la meilleure technologie disponible pour les clients.
          </h6>
        </div>
      </div>

      <div className="row affichageDynamique__title">
        <div className="col-12">
          <h2>Divertissez et informez vos clients</h2>
        </div>
      </div>

      <div className="row affichageDynamique__content">
        <div className="col-12">
          <img src="/assets/images/digitalisation/img41.png" alt="" />
        </div>
        <div className="col-12">
          <img src="/assets/images/digitalisation/img42.jpg" alt="" />
        </div>
        <div className="col-12">
          <img src="/assets/images/digitalisation/img43.jpg" alt="" />
        </div>
      </div>

      <div className="row affichageDynamique__content">
        <div className="col-12">
          <p>
            Chaque écran peut être utilisé. Notre lecteur multimédia Donatello;
            est un outil flexible et facile à contrôler qui facilite une
            surveillance précise. Sans effort, il vous permet de fournir
            facilement du contenu interactif aux kiosques, aux écrans
            numériques, à toutes les unités d'affichage, etc., afin que votre
            entreprise reste en contact avec les clients où qu'ils soient.
          </p>
          <p>
            Notre stratégie est de fournir aux entreprises des outils et des
            plates-formes afin qu'elles puissent communiquer et partager du
            contenu avec les marchés cibles à partir d'un emplacement central à
            tout moment, n'importe où.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AffichageDynamique;
