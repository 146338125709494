import React from 'react';
import { Link } from 'react-router-dom';
import "./Breadcrumb.scss";
import HomeIcon from '@material-ui/icons/Home';

function Breadcrumb({items, hidden}) {
    return (
        <div className="breadcrumb">
            <div className="container">
                <div className="row">
                    <span><Link to="/accueil"><HomeIcon/>&nbsp;</Link></span>
                    {items.map(item =>(
                        <span>&nbsp;/ &nbsp;<Link to={item.link}>{item.content}&nbsp;</Link></span>
                    ))}
                </div>
            </div>
    </div>
    )
}

export default Breadcrumb
