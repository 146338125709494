import React from 'react';
import { Link } from 'react-router-dom';
import './Finance.scss';

const Finance = () =>{
    return(
        <div className="finance container">
            <div className="row finance__title">
                <div className="col-12">
                    <h2>AFFICHAGE D'INFORMATIONS FINANCIERES</h2>
                </div>
            </div>

            <Link>
                <div className="row" data-aos="fade-right">
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/finance1.jpg" alt=""/>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>                        
                            <p>
                                <h3>Bandeau d'informations boursières</h3>
                            </p>
                            <p>
                                Les slogans d'actualités boursières à LED de Wavetec sont conçus avec des composants spéciaux pour assurer un défilement fluide en temps réel                        
                            </p>
                        </p>
                    </div>
                </div>
            </Link>

            <Link>
                <div className="row" data-aos="fade-left">
                    <div className="col-12 col-md-6">
                        <p>
                            <h3>Murs videos d'informations</h3>
                        </p>
                        <p>
                            Les slogans d'actualités boursières à LED de Wavetec sont conçus avec des composants spéciaux pour assurer un défilement fluide en temps réel                        
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/finance2.jpg" alt=""/>
                    </div>
                </div>
            </Link>

            <Link>
                <div className="row" data-aos="fade-right">
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/finance3.jpg" alt=""/>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>                        
                            <p>
                                <h3>Systèmes d'affichage d'information</h3>
                            </p>
                            <p>
                                Nous fournissons à nos clients un puissant logiciel haut de gamme Donatello pour la gestion de contenu, le partage et la diffusion d'informations
                            </p>
                        </p>
                    </div>
                </div>
            </Link>

            <Link>
                <div className="row" data-aos="fade-left">
                    <div className="col-12 col-md-6">
                        <p>
                            <h3>Stock Game</h3>
                        </p>
                        <p>
                            Stock Game est une simulation boursière virtuelle, un lien en temps réel avec le flux de données de la bourse, afin que vous puissiez comprendre la dynamique boursière
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/finance4.jpg" alt=""/>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Finance
