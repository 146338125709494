import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import "./GestionAttente.scss";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FileVirtuelle from "./FileVirtuelle";
import Breadcrumb from "../../Breadcrumb";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import FileLineaire from "./FileLineaire";
import FileMobile from "./FileMobile";

function GestionAttente() {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Breadcrumb
          items={[
            { link: "/portfolio", content: "Portfolio" },
            {
              link: "/portfolio/transformation-digitale",
              content: "Transformation digitale",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente",
              content: "Gestion de files d'attente",
            },
          ]}
        />
        <GestionAttenteContent />
      </Route>

      <Route path={`${url}/file-dattente-virtuelle`}>
        <Breadcrumb
          items={[
            { link: "/portfolio", content: "Portfolio" },
            {
              link: "/portfolio/transformation-digitale",
              content: "Transformation digitale",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente",
              content: "Gestion de files d'attente",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente/file-dattente-virtuelle",
              content: "File d'attente virtuelle",
            },
          ]}
        />
        <FileVirtuelle />
      </Route>

      <Route path={`${url}/file-dattente-lineaire`}>
        <Breadcrumb
          items={[
            { link: "/portfolio", content: "Portfolio" },
            {
              link: "/portfolio/transformation-digitale",
              content: "Transformation digitale",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente",
              content: "Gestion de files d'attente",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente/file-dattente-lineaire",
              content: "File d'attente linéaire",
            },
          ]}
        />
        <FileLineaire />
      </Route>

      <Route path={`${url}/file-dattente-mobile`}>
        <Breadcrumb
          items={[
            { link: "/portfolio", content: "Portfolio" },
            {
              link: "/portfolio/transformation-digitale",
              content: "Transformation digitale",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente",
              content: "Gestion de files d'attente",
            },
            {
              link:
                "/portfolio/transformation-digitale/gestion-de-file-dattente/file-dattente-mobile",
              content: "File d'attente mobile",
            },
          ]}
        />
        <FileMobile />
      </Route>
    </Switch>
  );
}

export default GestionAttente;

const GestionAttenteContent = () => {
  let { url } = useRouteMatch();
  return (
    <div className="gestionAttente container">
      <div className="row">
        <div className="col-12 gestionAttente__title">
          <h3>
            Diminuez le temps d'attente, servez plus de clients et augmentez
            l'efficacité du personnel
          </h3>
        </div>
      </div>

      <div className="row gestionAttente__content">
        <div className="col-12 col-md-6">
          <p>
            La solution de gestion des files d'attente de Centech réduit le
            temps d'attente des clients, améliore l'efficacité du service et
            augmente les revenus. La solution d'expérience client Centech est
            basée sur la philosophie Censphere et vise à ordonner, assister et
            calibrer les zones de service en attente et déconnectées, et
            améliorer l'expérience client aux points clés de l'entreprise.
          </p>
        </div>

        <div className="col-12 col-md-6">
          <p>
            Les files d'attente excessives sont les principales raisons du
            mécontentement des banques, des magasins de détail, des hôpitaux et
            des agences gouvernementales. Centech est l'un des plus grands
            fabricants et fournisseurs mondiaux de systèmes de gestion de files
            d'attente, avec plus de 27 ans d'expérience, qui peuvent aider les
            entreprises à améliorer les processus clients et à gérer les files
            d'attente. Avec des solutions de files d'attente virtuelles et
            linéaires et un puissant logiciel de gestion des files d'attente,
            nous pouvons «attendre».
          </p>
        </div>

        <div className="col-12">
          <p>
            Wavetec fournit des solutions de mise en file d'attente standard et
            personnalisables pour résoudre divers problèmes de mise en file
            d'attente rencontrés par les entreprises. Choisissez parmi les
            systèmes d'entreprise, de base ou mobiles pour gérer les foules et
            les files d'attente dans la zone de service. Notre puissant logiciel
            de gestion des files d'attente offre tout, des systèmes de files
            d'attente virtuelles et linéaires aux options de prise de
            rendez-vous et aux méthodes plus sophistiquées (par exemple, les
            applications mobiles. Nos solutions de file d'attente peuvent être
            intégrées à des solutions d'affichage numérique et des solutions de
            rétroaction client pour fournir L'expérience client globale. Wavetec
            a conçu des solutions d'expérience client pour une variété
            d'industries, vous permettant de vous adapter rapidement, de
            transformer la gamme de services et de réussir.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 gestionAttente__title">
          <h2>Solutions de file d'attente électronique</h2>
        </div>
      </div>

      <Link to={`${url}/file-dattente-virtuelle`}>
        <div className="row" data-aos="fade-up">
          <div className="col-12 col-md-6">
            <img src="/assets/images/attente1.jpg" alt="" />
          </div>
          <div className="col-12 col-md-6">
            <p>
              <p>
                <h3>Système de gestion de file d'attente virtuelle</h3>
              </p>
              <p>
                <h6>Solution Clé pour gérer le flux des clients</h6>
                Un système complet peut répondre à diverses exigences de mise en
                file d'attente, des systèmes de mise en file d'attente de base
                aux solutions d'entreprise complexes, multi-succursales et
                multirégionales. Notre système de gestion de file d'attente
                permet aux clients et aux visiteurs d'obtenir des billets via
                divers canaux tels que les bornes de billetterie en
                libre-service, la billetterie en ligne, les applications mobiles
                et les rendez-vous pour entrer dans la file d'attente. Vous êtes
                en ligne.
              </p>
              <p>
                <h6>Principaux atouts</h6>
                <p>
                  <RadioButtonUncheckedIcon />
                  Prévoyez avec précision le temps d'attente
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Vous permet de personnaliser les paramètres
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Intégration avec différentes plateformes
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Analyse prédictive et traçabilité client
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Fournir des informations sur les performances via des rapports
                  et des tableaux de bord
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Vous permet d'utiliser l'affichage numérique pour attirer les
                  clients en attente
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Créez votre propre solution - choisissez parmi les
                  fonctionnalités principales et optionnelles
                </p>
              </p>
            </p>
          </div>
        </div>
      </Link>

      <Link to={`${url}/file-dattente-lineaire`}>
        <div className="row" data-aos="fade-right">
          <div className="col-12 col-md-6">
            <p>
              <h3>Système de File d’Attente Linéaire</h3>
            </p>
            <p>
              <h6>Une file d'attente unique pour réduire le temps d'attente</h6>
              Ce système de file d'attente sans billet est un moyen économique
              de gérer la file d'attente de la manière la plus équitable et la
              plus rapide à la caisse de la pharmacie. S'il existe une file
              d'attente linéaire électronique, les clients sont traités sur la
              base du premier arrivé, premier servi, ce qui accélère le service.
              Lorsque les clients arrivent à la réception, lorsque le caissier
              appuie sur le bouton, ils seront appelés au prochain compteur
              disponible.
            </p>
            <p>
              <h6>Atouts majeurs</h6>
              <p>
                <RadioButtonUncheckedIcon />
                Idéal pour les transactions à court terme
              </p>

              <p>
                <RadioButtonUncheckedIcon />
                Facile à installer et à démarrer
              </p>

              <p>
                <RadioButtonUncheckedIcon />
                Le temps d'attente est inférieur à plusieurs files d'attente
              </p>

              <p>
                <RadioButtonUncheckedIcon />
                Les clients sont servis selon le principe du «premier arrivé,
                premier servi»
              </p>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="/assets/images/attente2.jpg" alt="" />
          </div>
        </div>
      </Link>

      <Link to={`${url}/file-dattente-mobile`}>
        <div className="row" data-aos="fade-down">
          <div className="col-12 col-md-6">
            <img src="/assets/images/attente1.jpg" alt="" />
          </div>
          <div className="col-12 col-md-6">
            <p>
              <p>
                <h3>Système Mobile de File d’Attente</h3>
              </p>
              <p>
                <h6>La Plateforme de la Révolution Mobile</h6>
                Faites un pas en avant avec l'application de file d'attente
                Mobile-Q de Centech. En permettant aux clients de rejoindre la
                file d'attente avant leur arrivée, la solution de retrait
                élimine les zones de service encombrées et optimise les
                processus clients. L'application permet également aux clients de
                choisir l'agence la plus proche d'eux, de récupérer rapidement
                leurs billets et de rejoindre la file d'attente quand c'est leur
                tour.
              </p>
              <p>
                <h6>Principaux atouts</h6>
                <p>
                  <RadioButtonUncheckedIcon />
                  Utilisez un smartphone pour attribuer un numéro de billet
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Fournir des directions à la succursale la plus proche
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Une fois votre numéro de billet fermé, l'application affichera
                  une alerte!
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Prenez rendez-vous en ligne
                </p>

                <p>
                  <RadioButtonUncheckedIcon />
                  Notification par SMS
                </p>
              </p>
            </p>
          </div>
        </div>
      </Link>

      <div className="row">
        <div className="col-12 gestionAttente__title">
          <h2>
            Avantages du système électronique de gestion des files d’attente
          </h2>
        </div>
      </div>

      <div className="row" data-aos="zoom-in">
        <div className="col-12 col-md-6">
          <p>
            <DoneAllIcon />
            Réduction du temps de services aux client de +50%
          </p>
          <p>
            <DoneAllIcon />
            Satisfaction client augmenté de 30%
          </p>
          <p>
            <DoneAllIcon />
            Suppression des files d’attentes non optimisé
          </p>
          <p>
            <DoneAllIcon />
            Expérience client parfaite
          </p>
        </div>

        <div className="col-12 col-md-6">
          <p>
            <DoneAllIcon />
            Maximalisation des performances et productivité du personnel
          </p>
          <p>
            <DoneAllIcon />
            Simple d’utilisation
          </p>
          <p>
            <DoneAllIcon />
            Intégration des composants du SGFA en toutes légitimités
          </p>
          <p>
            <DoneAllIcon />
            Mesure les kpi et augmente l’efficacité opérationnelle
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 gestionAttente__title">
          <h2>L'avantage Centech</h2>
        </div>
      </div>

      <div className="row" data-aos="zoom-out">
        <div className="col-12 col-md-6">
          <p>
            <RadioButtonCheckedIcon />
            Fournisseur de Solutions Rapides et Efficaces
          </p>
          <p>
            <RadioButtonCheckedIcon />
            Flux de travail personnalisables et systèmes évolutifs
          </p>
          <p>
            <RadioButtonCheckedIcon />3 Pôles de Développement Global
          </p>
        </div>

        <div className="col-12 col-md-6">
          <p>
            <RadioButtonCheckedIcon />
            Appui et Infrastructures Globaux
          </p>
          <p>
            <RadioButtonCheckedIcon />
            Prise en charge de l’intégration avec les systèmes existants
          </p>
          <p>
            <RadioButtonCheckedIcon />
            Développement Progressif de Produits et de Logiciels
          </p>
        </div>
      </div>
    </div>
  );
};
