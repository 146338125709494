import React from 'react';
import "./Home.scss";
import { UncontrolledCarousel } from 'reactstrap';
import Portfolio from './Portfolio';

function Home() {
    const slides=[
        {
            src:"/assets/images/sliders/slider1.jpg",
            altText:"",
            caption:'',
            header:"",
            key:"1"
        },
        {
            src:"/assets/images/sliders/digi10.jpg",
            altText:"",
            caption:'',
            header:"",
            key:"2"
        },
        {
            src:"/assets/images/sliders/slider3.jpg",
            altText:"",
            caption:'',
            header:"",
            key:"3"
        },
    ]
    return (
        <div className="home">

            <div  data-aos="zoom-in">
                <UncontrolledCarousel items={slides} />
            </div>

            <div className="container">
                <div className="row home__welcome">
                    <div className="col-12">
                        <h2> BIENVENUE CHEZ <span className="home__centech">CENTECH</span></h2>
                    </div>
                </div>
                <div className="row home__details">
                    <div className="col-12 col-md-6" data-aos="fade-right">
                        <div className="home__text">
                            <span className="home__centech">Centre d’Etude des Nouvelles Technologies et Energies</span> est une structure technique et 
                            commerciale installée au TOGO depuis Juin 2014. Elle intègre le Groupe WAVETEC depuis 
                            2016 et devient ainsi le support technique et commerciale dans la Zone Afrique Francophone.
                        </div>
                        <img 
                            className="home__image"
                            src="/assets/images/logo1.jpeg" 
                            alt=""
                        />
                    </div>
                    <div className="col-12 col-md-6" data-aos="fade-left">
                        <div className="home__text">
                            <span className="home__centech">CENTECH</span> fonde sa principale activité sur l’Ingénierie Informatique, la Transformation Digitale et 
                            l’Intégration des Réseaux d’entreprises et urbains. Pour ses décideurs, la culture de l’excellence 
                            et du travail bien fait est le cheval de bataille de tous les jours.
                        </div>
                        <img 
                            className="home__image"
                            src="/assets/images/img.jpg" 
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row  home__welcome">
                    <div className="col-12">
                        <h2>PORTFOLIO</h2>
                    </div>
                </div>
            </div>

            <Portfolio />


        </div>

    )
}

export default Home
