import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import "./Digitalisation.scss";
import Breadcrumb from "./Breadcrumb";
import ExpeClient from "./Digitalisation/GestionAttente/ExpeClient";
import Finance from "./Digitalisation/GestionAttente/Finance";
import GestionAttente from "./Digitalisation/GestionAttente/GestionAttente";
import AffichageDynamique from "./Digitalisation/AffichageDynamique/AffichageDynamique";
import SatisfactionClient from "./Digitalisation/SatisfactionClient/SatisfactionClient";
import Video from "./Video";
import { digitalisationVideos } from "./VideoConfig";

function Digitalisation() {
  let { path, url } = useRouteMatch();

  return (
    <div className="digitalisation">
      <Switch>
        <Route exact path={path}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              {
                link: "/portfolio/transformation-digitale",
                content: "Transformation digitale",
              },
            ]}
          />
          <ExpeClient />
          <Finance />
        </Route>

        <Route path={`${url}/gestion-de-file-dattente`}>
          <GestionAttente />
        </Route>

        <Route path={`${url}/affichage-dynamique`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              {
                link: "/portfolio/transformation-digitale",
                content: "Transformation digitale",
              },
              {
                link: "/portfolio/transformation-digitale/affichage-dynamique",
                content: "Affichage Dynamique",
              },
            ]}
          />
          <AffichageDynamique />
        </Route>

        <Route path={`${url}/satisfaction-client`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              {
                link: "/portfolio/transformation-digitale",
                content: "Transformation digitale",
              },
              {
                link: "/portfolio/transformation-digitale/satisfaction-client",
                content: "Satisfaction Client",
              },
            ]}
          />
          <SatisfactionClient />
        </Route>
      </Switch>

      <Video config={digitalisationVideos} />
    </div>
  );
}

export default Digitalisation;
