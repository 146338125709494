import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import "./Forums.scss";
import PortfolioItem from "./PortfolioItem";
import Video from "./Video";
import { forumVideos } from "./VideoConfig";

function Forums() {
  let { path, url } = useRouteMatch();

  const LOME_2019 = [
    "/assets/images/forums/STIL-LOME-2019/img2.jpg",
    "/assets/images/forums/STIL-LOME-2019/img3.jpg",
    "/assets/images/forums/STIL-LOME-2019/img4.jpg",
    "/assets/images/forums/STIL-LOME-2019/img5.jpg",
    "/assets/images/forums/STIL-LOME-2019/img6.jpg",
    "/assets/images/forums/STIL-LOME-2019/img7.jpg",
    "/assets/images/forums/STIL-LOME-2019/img8.jpg",
    "/assets/images/forums/STIL-LOME-2019/img9.jpg",
    "/assets/images/forums/STIL-LOME-2019/img10.jpg",
    "/assets/images/forums/STIL-LOME-2019/img11.jpg",
    "/assets/images/forums/STIL-LOME-2019/img12.jpg",
    "/assets/images/forums/STIL-LOME-2019/img13.jpg",
    "/assets/images/forums/STIL-LOME-2019/img14.jpg",
    "/assets/images/forums/STIL-LOME-2019/img15.jpg",
    "/assets/images/forums/STIL-LOME-2019/img16.jpg",
    "/assets/images/forums/STIL-LOME-2019/img17.jpg",
  ];

  const SOKODE_2018 = ["/assets/images/forums/STIL-SOKODE-2019/img2.jpg"];

  const KARA_2018 = [
    "/assets/images/forums/kara2018/img2.jpg",
    "/assets/images/forums/kara2018/img3.jpg",
    "/assets/images/forums/kara2018/img4.jpg",
    "/assets/images/forums/kara2018/img5.jpg",
    "/assets/images/forums/kara2018/img6.jpg",
    "/assets/images/forums/kara2018/img7.jpg",
    "/assets/images/forums/kara2018/img8.jpg",
    "/assets/images/forums/kara2018/img9.jpg",
    "/assets/images/forums/kara2018/img10.jpg",
    "/assets/images/forums/kara2018/img11.jpg",
    "/assets/images/forums/kara2018/img12.jpg",
    "/assets/images/forums/kara2018/img13.jpg",
    "/assets/images/forums/kara2018/img14.jpg",
    "/assets/images/forums/kara2018/img15.jpg",
    "/assets/images/forums/kara2018/img16.jpg",
    "/assets/images/forums/kara2018/img17.jpg",
    "/assets/images/forums/kara2018/img18.jpg",
    "/assets/images/forums/kara2018/img19.jpg",
    "/assets/images/forums/kara2018/img20.jpg",
    "/assets/images/forums/kara2018/img21.jpg",
    "/assets/images/forums/kara2018/img22.jpg",
    "/assets/images/forums/kara2018/img23.jpg",
    "/assets/images/forums/kara2018/img24.jpg",
    "/assets/images/forums/kara2018/img25.jpg",
    "/assets/images/forums/kara2018/img26.jpg",
    "/assets/images/forums/kara2018/img27.jpg",
    "/assets/images/forums/kara2018/img29.jpg",
  ];

  const LOME_2018 = [
    "/assets/images/forums/STIL-LOME-2018/img2.jpg",
    "/assets/images/forums/STIL-LOME-2018/img3.jpg",
    "/assets/images/forums/STIL-LOME-2018/img4.jpg",
    "/assets/images/forums/STIL-LOME-2018/img5.jpg",
    "/assets/images/forums/STIL-LOME-2018/img6.jpg",
    "/assets/images/forums/STIL-LOME-2018/img7.jpg",
    "/assets/images/forums/STIL-LOME-2018/img8.jpg",
    "/assets/images/forums/STIL-LOME-2018/img9.jpg",
    "/assets/images/forums/STIL-LOME-2018/img10.jpg",
    "/assets/images/forums/STIL-LOME-2018/img11.jpg",
    "/assets/images/forums/STIL-LOME-2018/img12.jpg",
    "/assets/images/forums/STIL-LOME-2018/img13.jpg",
    "/assets/images/forums/STIL-LOME-2018/img14.jpg",
    "/assets/images/forums/STIL-LOME-2018/img15.jpg",
    "/assets/images/forums/STIL-LOME-2018/img16.jpg",
    "/assets/images/forums/STIL-LOME-2018/img17.jpg",
  ];

  const FORUM_2019 = [
    "/assets/images/forums/forum2019/img2.jpg",
    "/assets/images/forums/forum2019/img3.jpg",
    "/assets/images/forums/forum2019/img4.jpg",
    "/assets/images/forums/forum2019/img5.jpg",
    "/assets/images/forums/forum2019/img6.jpg",
    "/assets/images/forums/forum2019/img7.jpg",
    "/assets/images/forums/forum2019/img8.jpg",
  ];

  const FORUM_2018 = [
    "/assets/images/forums/forum2018/frum2.jpg",
    "/assets/images/forums/forum2018/frum3.jpg",
  ];

  return (
    <div className="forums">
      <Switch>
        <Route exact path={path}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
            ]}
          />
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <PortfolioItem
                  link={`${url}/stil-lome-2019`}
                  imageSrc="/assets/images/forums/STIL-LOME-2019/img1.jpg"
                  title="STIL LOME 2019"
                  forum
                />
              </div>
              <div className="col-12 col-lg-6">
                <PortfolioItem
                  link={`${url}/sokode-tech-expo-2019`}
                  imageSrc="/assets/images/forums/STIL-SOKODE-2019/img1.jpg"
                  title="SOKODE TECH EXPO 2019"
                  forum
                />
              </div>
              <div className="col-12 col-lg-6">
                <PortfolioItem
                  link={`${url}/kara-tech-expo-2018`}
                  imageSrc="/assets/images/forums/kara2018/img1.jpg"
                  title="KARA TECH EXPO 2018"
                  forum
                />
              </div>
              <div className="col-12 col-lg-6">
                <PortfolioItem
                  link={`${url}/stil-lome-2018`}
                  imageSrc="/assets/images/forums/STIL-LOME-2018/img7.jpg"
                  title="STIL LOME 2018"
                  forum
                />
              </div>
              <div className="col-12 col-lg-6">
                <PortfolioItem
                  link={`${url}/forum-telecom-togo-2019`}
                  imageSrc="/assets/images/forums/forum2019/img1.jpg"
                  title="FORUM TÉLÉCOM TOGO 2019"
                  forum
                />
              </div>
              <div className="col-12 col-lg-6">
                <PortfolioItem
                  link="/"
                  imageSrc="/assets/images/forums/forum2018/frum2.jpg"
                  title="FORUM TÉLÉCOM TOGO 2018"
                  forum
                />
              </div>
            </div>
          </div>
          <Video config={forumVideos} />
        </Route>

        <Route path={`${url}/stil-lome-2019`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
              {
                link: "/portfolio/forums-salons/stil-lome-2019",
                content: "STIL LOME 2019",
              },
            ]}
          />
          <div className="container">
            <ForumsImg images={LOME_2019} />
          </div>
        </Route>

        <Route path={`${url}/sokode-tech-expo-2019`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
              {
                link: "/portfolio/forums-salons/sokode-tech-expo-2019",
                content: "SOKODE TECH EXPO 2019",
              },
            ]}
          />
          <div className="container">
            <ForumsImg images={SOKODE_2018} />
          </div>
        </Route>

        <Route path={`${url}/kara-tech-expo-2018`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
              {
                link: "/portfolio/forums-salons/kara-tech-expo-2018",
                content: "KARA TECH EXPO 2018",
              },
            ]}
          />
          <div className="container">
            <ForumsImg images={KARA_2018} />
          </div>
        </Route>

        <Route path={`${url}/stil-lome-2018`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
              {
                link: "/portfolio/forums-salons/stil-lome-2018",
                content: "STIL LOME 2018",
              },
            ]}
          />
          <div className="container">
            <ForumsImg images={LOME_2018} />
          </div>
        </Route>

        <Route path={`${url}/forum-telecom-togo-2019`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
              {
                link: "/portfolio/forums-salons/forum-telecom-togo-2019",
                content: "FORUM TÉLÉCOM TOGO 2019",
              },
            ]}
          />
          <div className="container">
            <ForumsImg images={FORUM_2019} />
          </div>
        </Route>

        <Route path={`${url}/forum-telecom-togo-2018`}>
          <Breadcrumb
            items={[
              { link: "/portfolio", content: "Portfolio" },
              { link: "/portfolio/forums-salons", content: "Forums et Salons" },
              {
                link: "/portfolio/forums-salons/forum-telecom-togo-2018",
                content: "FORUM TÉLÉCOM TOGO 2018",
              },
            ]}
          />
          <div className="container">
            <ForumsImg images={FORUM_2018} />
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default Forums;

const ForumsImg = ({ images }) => {
  return (
    <div className="forumsImg row">
      {images.map((image) => {
        return (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <img src={image} alt="" />
          </div>
        );
      })}
    </div>
  );
};
