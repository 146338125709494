import React, { useState } from 'react';
import "./Header.scss"
import { NavLink } from 'react-router-dom'
import { Collapse, Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap'
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';

function Header() {
    const [toggleNav, setToggleNav] = useState(false);

    return (
        <div className="header__up">
            <Navbar light expand="md" className="header">
                    <div className="container">
                        <NavbarBrand className="mr-auto" href="/accueil">
                            <img 
                                className="header__logo"
                                src="/assets/images/logo.jpg"
                                alt="logo"
                            />
                        </NavbarBrand>
                        <IconButton className="header__button d-md-none">
                            <MenuIcon
                                className="d-md-none"
                                onClick={() => setToggleNav(!toggleNav)} 
                            />
                        </IconButton>

                        <Collapse isOpen={toggleNav} navbar>
                        <Nav navbar className="ml-auto header__firstNav">
                            <NavItem>
                                <NavLink className="nav-link" to="/accueil">
                                    Accueil
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to="/services">
                                    Services
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to="/portfolio">
                                    Portfolio
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to="/team">
                                    Qui Sommes Nous ?
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to="/contact">
                                    Contact
                                </NavLink>
                            </NavItem>
                        </Nav>
    
                        <Nav className="ml-md-3" navbar>
                            <NavItem className="header__social">
                                <a href="https://www.twitter.com">
                                    <TwitterIcon className="header__socialIcon" />
                                </a>
                                <a href="https://www.facebook.com/CentechWavetecAfriqueFrancophone/">
                                    <FacebookIcon className="header__socialIcon" />
                                </a>
                                <a href="https://instagram.com/centech.tg?igshid=jukhhmn84e96">
                                    <InstagramIcon className="header__socialIcon" />
                                </a>
                                <a href="https://www.linkedin.com/company/centech-togo">
                                    <LinkedInIcon className="header__socialIcon" />
                                </a>
                            </NavItem>
                </Nav>
                        </Collapse>
                    </div>
                </Navbar>
                
        </div>
    )
}

export default Header
