import React from "react";
import "./Team.scss";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";

function Team() {
  return (
    <div className="team">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6" data-aos="fade-right">
            <p>
              La volonté de ses promoteurs est de permettre aux Entreprises et
              Organismes de la sous-région Ouest Africaine de se mettre au
              diapason des Nouvelles Technologies par un apport de solutions et
              prestations de qualité, ainsi qu’un transfert de compétences et de
              technologie.
            </p>
          </div>
          <div className="col-12 col-md-6" data-aos="fade-left">
            <p>
              CENTECH Centre d’Etude des Nouvelles Technologies et Energies est
              une structure technique et commerciale installée au TOGO depuis
              Juin 2014. Elle intègre le Groupe WAVETEC depuis 2016 et devient
              ainsi le support technique et commerciale dans la Zone Afrique
              Francophone du Groupe Anglais, l’un des leaders en Transformation
              Digitale.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="team__introduction">
        <div className="container">
          <div className="row">
            <div className="col-12 team__introductionTitle" data-aos="fade-up">
              <h2>NOTRE EQUIPE</h2>
            </div>

            <div className="col-12 col-sm-4 col-md-3" data-aos="zoom-in">
              <Member
                imageSrc="/assets/images/directeur.jpg"
                links={{
                  twitter: "https://twitter.com/SogodoSteven",
                  facebook: "https://www.facebook.com/TekoStevenSogodo",
                  instagram:
                    "https://instagram.com/stevensogodo?igshid=1w734pjftxesw",
                  linkedin:
                    "https://www.linkedin.com/in/steven-teko-sogodo-660764118",
                }}
                name="Steven Têko Sogodo"
                role="Président du conseil d'administration"
              />
            </div>

            <div className="col-12 col-sm-4 col-md-3" data-aos="zoom-in">
              <Member
                imageSrc="/assets/images/tanguy.jpeg"
                links={{
                  twitter: "https://twitter.com/SogodoSteven",
                  facebook: "https://www.facebook.com/TekoStevenSogodo",
                  instagram:
                    "https://instagram.com/stevensogodo?igshid=1w734pjftxesw",
                  linkedin:
                    "https://www.linkedin.com/in/steven-teko-sogodo-660764118",
                }}
                name="Tanguy Atayi"
                role="Développeur"
              />
            </div>

            <div className="col-12 col-sm-4 col-md-3" data-aos="zoom-in">
              <Member
                imageSrc="/assets/images/bryan.jpeg"
                links={{
                  twitter: "https://twitter.com/SogodoSteven",
                  facebook: "https://www.facebook.com/TekoStevenSogodo",
                  instagram:
                    "https://instagram.com/stevensogodo?igshid=1w734pjftxesw",
                  linkedin:
                    "https://www.linkedin.com/in/steven-teko-sogodo-660764118",
                }}
                name="Brayan Lawson"
                role="Designer"
              />
            </div>

            <div className="col-12 col-sm-4 col-md-3" data-aos="zoom-in">
              <Member
                imageSrc="/assets/images/juniorPic.png"
                links={{
                  twitter: "https://twitter.com/SogodoSteven",
                  facebook: "https://www.facebook.com/TekoStevenSogodo",
                  instagram:
                    "https://instagram.com/stevensogodo?igshid=1w734pjftxesw",
                  linkedin:
                    "https://www.linkedin.com/in/steven-teko-sogodo-660764118",
                }}
                name="Junior Ambourouet"
                role="Consultant Digital"
              />
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-12 col-sm-4 col-md-3" data-aos="zoom-in">
              <Member
                imageSrc="/assets/images/TIASS.jpg"
                links={{
                  twitter: "https://twitter.com/SogodoSteven",
                  facebook: "https://www.facebook.com/TekoStevenSogodo",
                  instagram:
                    "https://instagram.com/stevensogodo?igshid=1w734pjftxesw",
                  linkedin:
                    "https://www.linkedin.com/in/steven-teko-sogodo-660764118",
                }}
                name="Kévin de TIASSOU"
                role="Ingénieur informatique"
              />
            </div>
          </div>
        </div>
      </div> */}

      <Partners />
    </div>
  );
}

export default Team;

const Member = ({ imageSrc, links, name, role }) => {
  return (
    <div className="member">
      <div className="member__up">
        <img className="member__image" src={imageSrc} alt="" />

        <div className="member__social">
          <a href={links?.twitter}>
            <TwitterIcon className="member__socialIcon" />
          </a>
          <a href={links?.facebook}>
            <FacebookIcon className="member__socialIcon" />
          </a>
          <a href={links?.instagram}>
            <InstagramIcon className="member__socialIcon" />
          </a>
          <a href={links?.linkedin}>
            <LinkedInIcon className="member__socialIcon" />
          </a>
        </div>
      </div>

      <div className="member__info">
        <h5>{name}</h5>
        <span>{role}</span>
      </div>
    </div>
  );
};

const Partners = () => {
  const items = [
    {
      imageSrc: "wev.png",
      link: "https://www.wavetec.com/",
    },
    {
      imageSrc: "afrique_it.jpeg",
      link: "",
    },
    {
      imageSrc: "canon.jpg",
      link: "",
    },
    {
      imageSrc: "cisco.png",
      link: "",
    },
    {
      imageSrc: "dell.jpg",
      link: "",
    },
    {
      imageSrc: "sdmo.png",
      link: "",
    },
    {
      imageSrc: "alcatel.jpg",
      link: "",
    },
    {
      imageSrc: "wireless.png",
      link: "",
    },
    {
      imageSrc: "osram.png",
      link: "",
    },
    {
      imageSrc: "avaya.png",
      link: "",
    },
    {
      imageSrc: "expert.png",
      link: "",
    },
    {
      imageSrc: "schneider.png",
      link: "",
    },
    {
      imageSrc: "legrand.png",
      link: "",
    },
    {
      imageSrc: "falmson.jpg",
      link: "",
    },
    {
      imageSrc: "hyper.jpg",
      link: "",
    },
    {
      imageSrc: "hp.jpg",
      link: "",
    },
    {
      imageSrc: "apc.png",
      link: "",
    },
    {
      imageSrc: "ortea.png",
      link: "",
    },
    {
      imageSrc: "nexans.png",
      link: "",
    },
    {
      imageSrc: "zenit.jpg",
      link: "",
    },
    {
      imageSrc: "siemens.png",
      link: "",
    },
    {
      imageSrc: "thorn.png",
      link: "",
    },
    {
      imageSrc: "philips.png",
      link: "",
    },
    {
      imageSrc: "york.jpg",
      link: "",
    },
    {
      imageSrc: "helita.png",
      link: "",
    },
    {
      imageSrc: "ciat.png",
      link: "",
    },
    {
      imageSrc: "tdsi.png",
      link: "",
    },
    {
      imageSrc: "france-air.png",
      link: "",
    },
  ];
  return (
    <div className="partners container" data-aos="fade-up">
      <div className="row">
        <div className="col-12 partners__title">
          <h2>NOS PARTENAIRES</h2>
        </div>
      </div>

      <div className="row partners__wrap">
        {items.map((item) => (
          <div className="col-6 col-md-4 col-lg-3" data-aos="flip-down">
            <Partner imageSrc={item?.imageSrc} link={item?.link} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Partner = ({ imageSrc, link }) => {
  return (
    <div className="partner">
      <a href={link}>
        <img src={`/assets/images/partenaires/${imageSrc}`} alt="" />
      </a>
    </div>
  );
};
