import React from "react";
import "./SatisfactionClient.scss";

function SatisfactionClient() {
  return (
    <div className="satisfactionClient container">
      <div className="row satisfactionClient__title">
        <div className="col-12">
          <h2>CULTIVER UNE EXPÉRIENCE CLIENT RAFFINÉE</h2>
        </div>
      </div>

      <div className="row satisfactionClient__content">
        <div className="col-12 satisfactionClient__image">
          <img src="/assets/images/digitalisation/img44.jpg" alt="" />
        </div>
        <div className="col-12">
          <p>
            Les besoins et les attentes des clients changent constamment, les
            commentaires et opinions des clients sont donc essentiels pour une
            entreprise centrée sur le client. Opinion Plus de Centech est la clé
            pour extraire les données des commentaires des clients et comprendre
            ce qu'ils attendent de vous. Opinion Plus est un puissant système de
            commentaires des clients qui peut combler le fossé entre vous et la
            valeur de service perçue par vos clients. De plus, il vous permet
            d'utiliser ce retour d'expérience pour mieux contrôler l'expérience
            client
          </p>
        </div>
      </div>

      <div className="row satisfactionClient__flexRow satisfactionClient__flexRowFirst satisfactionClient__flexRowSecond">
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img18.png" alt="" />
          Mesurer la satsifaction client en temps réel
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img21.png" alt="" />
          Suivi de la performance de l'encadrement et du personnel
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img20.png" alt="" />
          Montrer votre engagement envers l'amélioration du service
        </div>
        <div className="col-6 col-md-3">
          <img src="/assets/images/digitalisation/img16.png" alt="" />
          Prendre des décisions stratégiques basées sur des rapports dynamiques
        </div>
      </div>

      <div className="row satisfactionClient__content">
        <div className="col-12">
          <img src="/assets/images/digitalisation/img13.png" alt="" />
        </div>
      </div>

      <div className="row satisfactionClient__title">
        <div className="col-12">
          <h2>Principaux avantages</h2>
        </div>
      </div>

      <div className="row satisfactionClient__flexRow satisfactionClient__flexRowFirst satisfactionClient__flexRowSecond">
        <div className="col-6 col-md-4">
          <img src="/assets/images/digitalisation/img34.png" alt="" />
          Permet une information ciblée
        </div>
        <div className="col-6 col-md-4">
          <img src="/assets/images/digitalisation/img17.png" alt="" />
          Aide les entreprises a comparer leur performance
        </div>
        <div className="col-6 col-md-4">
          <img src="/assets/images/digitalisation/img19.png" alt="" />
          Pilotage de la satisfaction client en temps réel depuis un point
          central
        </div>
        <div className="col-6 col-md-4">
          <img src="/assets/images/digitalisation/img22.png" alt="" />
          Augmente la loyauté de la clientèle
        </div>
        <div className="col-6 col-md-4">
          <img src="/assets/images/digitalisation/img21.png" alt="" />
          Améliore la fidélisation de la clientèle
        </div>
        <div className="col-6 col-md-4">
          <img src="/assets/images/digitalisation/img19.png" alt="" />
          Pilotage et mesure en temps réel de la performance du personnel
        </div>
      </div>

      <div className="row satisfactionClient__title">
        <div className="col-12">
          <h2>Caractéristiques du produit</h2>
        </div>
      </div>

      <div className="row satisfactionClient__flexRow satisfactionClient__flexRowFirst">
        <div className="col-12 col-md-6">
          <h2>INTÉGRÉ</h2>
          <h6>
            Pour faciliter l'interaction avec les appareils externes, Opinion
            Plus s'intègre de manière transparente à d'autres plates-formes
            technologiques, y compris les systèmes de gestion de files
            d'attente, les terminaux de point de vente et les logiciels CRM.
          </h6>
        </div>
        <div className="col-12 col-md-6">
          <h2>INTERACTIF</h2>
          <h6>
            Comprendre le lien client/entreprise est primordial. Opinion Plus
            offre aux entreprises un retour-client instantané, aidant à
            renforcer cette relation et à améliorer la qualité du service.
          </h6>
        </div>
        <div className="col-12 col-md-6">
          <h2>CENTRALISÉ</h2>
          <h6>
            Afin de permettre l'accès aux données depuis n'importe quel endroit,
            Opinion Plus peut également se connecter à une plateforme de cloud
            privé.
          </h6>
        </div>
        <div className="col-12 col-md-6">
          <h2>DYNAMIQUE</h2>
          <h6>
            Conçu intelligemment, Opinion Plus affiche des messages ciblés
            pendant le service au client -une communication de messages
            personnalisés à un auditoire ciblé.
          </h6>
        </div>
      </div>
    </div>
  );
}

export default SatisfactionClient;
