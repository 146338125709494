import React from 'react';
import './MiMoney.scss'
import DoneAllIcon from '@material-ui/icons/DoneAll';

function MiMoney() {
    return (
        <div className="miMoney container">
            <div className="row" data-aos="fade-down">
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/money.jpg" alt=""/>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-right">
                    <p>                        
                        <p>
                        Qu’est-ce que le <strong>Mi Money</strong> ?
                        Le <strong>Mi Money</strong> est une technologie qui permet aux gens de recevoir, garder et dépenser de l’argent en utilisant un téléphone portable.
                        </p>
                        <p>Envoi et dépenses:</p>
                        <p><DoneAllIcon /> En utilisant le menu ou l’application du portefeuille mobile sur leur téléphone portable, les utilisateurs peuvent transférer des fonds à quelqu’un ou payer des sociétés comme des magasins ou des restaurants.</p>
                        <p><DoneAllIcon /> Les utilisateurs peuvent aussi retirer de l’argent de leur portefeuille mobile auprès des agences dans leur pays.</p>
                        <p><DoneAllIcon /> Les portefeuilles mobiles sont une alternative populaire aux espèces et aux banques parce qu’ils sont faciles à utiliser, sécurisés et on peut les utiliser partout où il y a un signal de téléphone portable.</p>
                    </p>
                </div>
            </div>

            <div className="row" data-aos="fade-up">
                <div className="col-12 col-md-6" data-aos="fade-right">
                    Envoyer vers un compte <strong>Mi Money</strong>:
                    <p>Si vous avez déjà envoyé un dépôt bancaire ou un retrait d’espèces, vous pouvez vouloir essayer un transfert vers un portefeuille mobile.</p>
                    <p>Les transferts vers un mobile se réalisent instantanément d’habitude, jour comme nuit.</p>
                    <p>Le bénéficiaire du transfert n’a pas besoin de se déplacer pour le recevoir.</p>
                    <p>Les bénéficiaires recevront une notification sur leur téléphone portable quand l’argent est crédité à leur compte. Les expéditeurs du transfert recevront aussi une notification une fois le transfert terminé.</p>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                    <img src="/assets/images/transfer.jpg" alt=""/>
                </div>
            </div>       

            <div className="row" data-aos="fade-down">
                <div className="col-12">
                    Envoyer vers un portefeuille mobile est facile avec CASH MONEY. En fait, nous sommes connectés 
                    à plus de services de Mobile Money que tout autre fournisseur de transfert d’argent. Nous envoyons 
                    toujours votre argent aux personnes que vous aimez rapidement. Comment? Nous avons déjà des fonds 
                    dans tous les pays disponibles, donc il n'y a pas d'attente. Et, dès que votre transaction sera 
                    terminée, vous et votre destinataire recevrez un SMS et un courrier électronique.
                </div>
            </div>     
        </div>
    )
}

export default MiMoney
